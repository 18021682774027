import { LockClosedIcon, LockOpenIcon } from '@papershift/ui/src/icons'
import type { Chat } from '@papershift/api/src/chat'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import type { Option } from '@papershift/ui/src/Dropdown.vue'
import useChatStore from '@/stores/chat/chat.store'
import { notify } from '@papershift/ui/src/Notifier'

export type Action = 'close_chat' | 'open_chat'
type ActionHandler = (row: Chat) => void
type Listeners = Record<Action, ActionHandler>

const { t } = i18n.global

export default function useTableActions() {
  const { updateChat } = useChatStore()
  const listeners: Listeners = {} as Listeners

  function getActions(chat: Chat) {
    const actions: Option<Action>[] = []

    if (chat.permissions?.actions?.edit) {
      if (chat.open) {
        actions.push({
          label: t('actions.close_chat'),
          value: 'close_chat',
          icon: LockClosedIcon,
          theme: 'danger',
        })
      } else {
        actions.push({
          label: t('actions.open_chat'),
          value: 'open_chat',
          icon: LockOpenIcon,
        })
      }
    }

    return actions
  }

  async function handleAction(row: Chat, actionOption: Option<Action>) {
    listeners[actionOption.value]?.(row)

    if (actionOption.value === 'close_chat') {
      await closeChat(row)
    } else {
      await reopenChat(row)
    }
  }

  async function closeChat(row: Chat) {
    try {
      await updateChat(row.id, { open: false })
      notify({
        title: t('success.close_chat.title'),
        message: t('success.close_chat.message'),
      })
    } catch {
      notify({
        title: t('error.close_chat.title'),
        message: t('error.close_chat.message'),
        type: 'error',
      })
    }
  }

  async function reopenChat(row: Chat) {
    try {
      await updateChat(row.id, { open: true })
      notify({
        title: t('success.open_chat.title'),
        message: t('success.open_chat.message'),
      })
    } catch {
      notify({
        title: t('error.open_chat.title'),
        message: t('error.open_chat.message'),
        type: 'error',
      })
    }
  }

  return {
    getActions,
    handleAction,
    onAction(action: Action, handler: ActionHandler) {
      listeners[action] = handler
    },
  }
}

addMessages({
  en: {
    actions: {
      open_chat: 'Reopen Chat',
      close_chat: 'Close Chat',
    },
    success: {
      close_chat: {
        title: 'Chat closed',
        message: 'The chat has been successfully closed.',
      },
      open_chat: {
        title: 'Chat reopened',
        message: 'The chat has been successfully reopened.',
      },
    },
    error: {
      close_chat: {
        title: 'Error',
        message: 'An error occurred while closing the chat.',
      },
      open_chat: {
        title: 'Error',
        message: 'An error occurred while reopening the chat.',
      },
    },
  },
  de: {
    actions: {
      open_chat: 'Chat wieder öffnen',
      close_chat: 'Chat schließen',
    },
    success: {
      close_chat: {
        title: 'Chat geschlossen',
        message: 'Der Chat wurde erfolgreich geschlossen.',
      },
      open_chat: {
        title: 'Chat wieder geöffnet',
        message: 'Der Chat wurde erfolgreich wieder geöffnet.',
      },
    },
    error: {
      close_chat: {
        title: 'Fehler',
        message: 'Beim Schließen des Chats ist ein Fehler aufgetreten.',
      },
      open_chat: {
        title: 'Fehler',
        message: 'Beim Wiederöffnen des Chats ist ein Fehler aufgetreten.',
      },
    },
  },
})
