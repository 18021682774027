import { createConsumer } from '@anycable/web'

const CABLE_URL = import.meta.env.VITE_CABLE_URL

let consumerInstance: any

export const createCable = () => {
  if (!consumerInstance) {
    const authState = JSON.parse(localStorage.getItem('auth') ?? '{}')
    const authToken = authState?.authToken?.split(' ')[1]

    const authorizedCableURL = authToken
      ? `${CABLE_URL}?jid=${authToken}`
      : CABLE_URL

    consumerInstance = createConsumer(authorizedCableURL, {
      protocol: 'actioncable-v1-ext-json',
      protocolOptions: {
        pongs: true,
      },
    })
  }

  return consumerInstance
}

export const destroyCable = () => {
  if (consumerInstance) {
    consumerInstance.disconnect()
    consumerInstance = null
  }
}

export const createChannel = (...args: any[]) => {
  const consumer = createCable()
  return consumer.subscriptions.create(...args)
}
