import { ref } from 'vue'
import { defineStore } from 'pinia'
import { flattenRecord } from '@papershift/jsonapi/src/utils'
import { type AppPermissions, getAppPermissions } from '@papershift/api/src/ui'
import { clearState } from '../utils'

const permissionStore = defineStore('permission', () => {
  const state = {
    appPermissions: ref<AppPermissions | null>(null),
  }
  let appPermissionsRequest: ReturnType<typeof getAppPermissions> | null = null

  function $resetState() {
    clearState(state)
  }

  /**
   * If a request is already running,
   * this will just await that request instead of firing a new one.
   */
  async function fetchAppPermissions() {
    if (appPermissionsRequest) {
      await appPermissionsRequest
      return
    }

    appPermissionsRequest = getAppPermissions()
    const { data } = await appPermissionsRequest
    appPermissionsRequest = null

    state.appPermissions.value = flattenRecord(data)
  }

  return {
    $resetState,

    appPermissions: state.appPermissions,

    fetchAppPermissions,
  }
})

export default permissionStore
