import { isReadonly, type Ref } from 'vue'
import type {
  State,
  RecordGeneric,
  RecordRelationship,
  JsonApiDataItem,
} from './types'
import { linkRelationship } from './_relationship.utils'

// restructures given jsonapi record by flattening it via flattenRecord()
// util and adding relationships getters using linkRelationship() util
export function restructureRecord<T extends RecordGeneric>(
  record: JsonApiDataItem<
    T,
    { id?: never; type?: never; _relationships?: never }
  >,
  state: State
): T {
  const flatRecord = flattenRecord(record)

  if (record.relationships) {
    Object.keys(record.relationships).forEach((relName) => {
      linkRelationship(state, flatRecord, relName)
    })
  }

  return flatRecord
}

// takes raw jsonapi record and returns restructured version, which moves all
// `attributes` one level up:
// > flattenRecord({id: '1', type: 'type', relationships: {}, attributes: {key: 'val'}, meta: {permissions: {}}})
// > {id: '1', type: 'type', _relationships: {}, permissions: {}, key: 'val'}
export function flattenRecord<T>(
  record: JsonApiDataItem<
    T,
    { id?: never; type?: never; _relationships?: never }
  >
): T {
  if ('id' in record.attributes) {
    throw new Error('Record attributes cannot contain an "id" property')
  }
  if ('type' in record.attributes) {
    throw new Error('Record attributes cannot contain a "type" property')
  }

  return {
    id: record.id,
    type: record.type,
    _relationships: record.relationships,
    ...record.attributes,
    ...record.meta,
  }
}

// creates object from jsonapi `included` array. Key represents relationship
// name and value is array of data. Result will be similar to following:
// {
//   rel1: [item, item],
//   rel2: [item, item, item],
// }
export function extractIncluded(included: RecordGeneric[] = []) {
  const obj: Record<string, RecordGeneric[]> = {}

  included.forEach((item) => {
    if (!obj[item.type]) {
      obj[item.type] = []
    }
    obj[item.type].push(item)
  })

  return obj
}

// extracts relationships' IDs of all given records into single Map with
// relationship name as a key and Set of IDs as a value:
// Map{
//   apples: Set{'1', '2'},
//   oranges: Set{'11', '22'}
// }
// second param can be used to specify only necessary relations:
// extractRelationships(records, ['oranges'])
export function extractRelationships(
  records: Array<{ relationships: Record<string, RecordRelationship> }> = [],
  includeRels: string[] = []
): Map<string, Set<string>> {
  const map = new Map()
  const includeAllRels = includeRels.length === 0

  for (const { relationships } of records) {
    if (!relationships) {
      continue
    }

    // go through each relationship of the record
    for (const [relName, { data }] of Object.entries(relationships)) {
      if (!data) {
        continue
      }
      // when relations specified explicitly, we should skip non-matching ones
      if (!includeAllRels && !includeRels.includes(relName)) {
        continue
      }

      if (!map.has(relName)) {
        map.set(relName, new Set())
      }

      const relNameSet = map.get(relName)

      // relationships can be one-to-many and one-to-one as well
      if (Array.isArray(data)) {
        for (const { id } of data) {
          relNameSet.add(id)
        }
      } else {
        relNameSet.add(data.id)
      }
    }
  }
  return map
}

// checks if `input` is valid ID accepted by backend API
export function isValidId(input: string): boolean {
  return typeof input === 'string' && input !== '0' && /^\d+$/.test(input)
}

export function getRef(state: State, type: string) {
  if (!state.records[type]) {
    throw new Error(`Unknown record type: ${type}`)
  }
  if (isReadonly(state.records[type])) {
    throw new Error(`${type} record type is a read-only getter`)
  }

  // cannot use type guard, because: https://github.com/microsoft/TypeScript/issues/10530
  // so we have to use `as` here
  return state.records[type] as Ref<RecordGeneric[]>
}

// separates array of records to ones with ID and ones without
// used internally in jsonapi mutation utils
export function classifyRecords(records: RecordGeneric[]) {
  const withId = []
  const withoutId = []

  for (const m of records) {
    if (typeof m.id === 'undefined') {
      withoutId.push(m)
    } else {
      withId.push(m)
    }
  }

  return {
    withId,
    withoutId,
  }
}
