<script setup lang="ts" generic="T">
import { computed, type Component } from 'vue'

const props = withDefaults(
  defineProps<{
    active: boolean
    icon?: Component
    label: string
    theme?: 'default' | 'danger'
  }>(),
  {
    theme: 'default',
  }
)

const classes = computed(() => {
  let global = [props.active ? 'bg-gray-200 text-gray-900' : 'text-gray-700']
  let icon = 'text-gray-400 group-hover:text-gray-500'

  if (props.theme === 'danger') {
    global = [props.active ? 'bg-red-100 text-red-800' : 'text-red-700']
    icon = 'text-red-400 group-hover:text-red-500'
  }

  return {
    global,
    icon,
  }
})
</script>

<template>
  <a
    href="#"
    class="group flex items-center px-4 py-2 text-sm"
    :class="classes.global"
  >
    <component
      :is="icon"
      v-if="icon"
      class="mr-3 h-4 w-4"
      :class="classes.icon"
      aria-hidden="true"
    />
    {{ label }}
  </a>
</template>
