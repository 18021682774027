import { watch } from 'vue'
import { useWindowFocus } from '@vueuse/core'
import useAuthStore from '@/stores/auth/auth.store'
import usePermissionStore from '@/stores/permission/permission.store'

export default function handleWindowFocus() {
  const isFocused = useWindowFocus()
  const authStore = useAuthStore()
  const { fetchAppPermissions } = usePermissionStore()

  watch(
    () => isFocused.value,
    () => {
      if (isFocused.value && authStore.user) {
        fetchAppPermissions()
      }
    }
  )
}
