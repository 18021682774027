import type { JsonApiResponse } from '@papershift/jsonapi/src/types'
import type { PathsToStringProps, ViewCreatePermission } from './types'
import api from './api'
import type { Join } from 'type-fest/source/join'

export type AppPermissions = {
  id: string
  type: 'app_permissions'
  chat: { view: boolean }
  offboarding: ViewCreatePermission
  offboarding_stage: { create: boolean }
  onboarding: ViewCreatePermission
  onboarding_stage: { create: boolean }
  settings: { view: boolean }
  user: ViewCreatePermission
}

export type AppPermissionPaths = Join<PathsToStringProps<AppPermissions>, '.'>

export function getAppPermissions(): Promise<JsonApiResponse<AppPermissions>> {
  return api.url('/ui/v1/app-permissions').get().json()
}
