import api from './api'
import type { LocaleCode } from '@papershift/locale/src/i18n'
import type { Account } from './account'
import type { User } from './user'
import type { JsonApiResponse } from '@papershift/jsonapi/src/types'

export type Identity = {
  id: string
  email: string
  last_used_account_id: string
}
export type SigninParams = Omit<Identity, 'id' | 'last_used_account_id'> & {
  password: string
}
export type RegistrationParams = {
  identity: SigninParams
  user: {
    name: string
  }
  account: {
    name: string
  }
  display_settings: {
    language: LocaleCode
  }
  terms: {
    accepted: boolean
  }
}
export type ResetPasswordParams = {
  email: string
}
export type UpdatePasswordParams = {
  reset_password_token: string
  password: string
}

export type AuthResponse = JsonApiResponse<
  Identity & { accounts: Account[] }
> & {
  authToken: string
}

export async function register(
  params: RegistrationParams
): Promise<AuthResponse> {
  const response = await api
    .url('/auth/register')
    .post({
      data: {
        type: 'registration',
        attributes: params,
      },
    })
    .res()
  const body = await response.json()

  return {
    authToken: response.headers.get('Authorization'),
    ...body,
  }
}

export async function signin(params: SigninParams): Promise<AuthResponse> {
  const response = await api
    .url('/auth/signin')
    .post({
      data: {
        type: 'identity',
        attributes: params,
      },
    })
    .res()
  const body = await response.json()

  return {
    authToken: response.headers.get('Authorization'),
    ...body,
  }
}

export function signout() {
  return api.url('/auth/signout').delete().res()
}

export function resetPassword(params: ResetPasswordParams): Promise<unknown> {
  return api
    .url('/auth/reset_password')
    .post({
      data: {
        type: 'identity',
        attributes: params,
      },
    })
    .res()
}

export function updatePassword(params: UpdatePasswordParams) {
  return api
    .url('/auth/reset_password')
    .put({
      data: {
        type: 'identity',
        attributes: params,
      },
    })
    .json()
}

export function getInvitedUser(
  inviteToken: string
): Promise<JsonApiResponse<User>> {
  return api.url(`/auth/join/${inviteToken}?include=account`).get().json()
}

export async function acceptInvite(
  inviteToken: string,
  userEmail: string,
  userPassword: string
): Promise<AuthResponse> {
  const response = await api
    .url(`/auth/join/${inviteToken}`)
    .post({
      data: {
        type: 'identity',
        attributes: {
          email: userEmail,
          password: userPassword,
        },
      },
    })
    .res()
  const body = await response.json()

  return {
    authToken: response.headers.get('Authorization'),
    ...body,
  }
}
