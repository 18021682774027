import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { differenceInSeconds } from 'date-fns'
import { flattenRecord, restructureRecord } from '@papershift/jsonapi/src/utils'
import * as api from '@papershift/api/src/chat'
import * as paginationApi from '@papershift/api/src/pagination'
import type { Role } from '@papershift/api/src/role'
import type { User } from '@papershift/api/src/user'
import { clearState } from '../utils'
import useAuthStore from '@/stores/auth/auth.store'
import {
  setRecords,
  addIncluded,
} from '@papershift/jsonapi/src/records.mutations'
import type {
  JsonApiListResponse,
  JsonApiMeta,
} from '@papershift/jsonapi/src/types'
import {
  type FilterItem,
  FilterOperator,
} from '@papershift/api/src/filter-utils'

type ChatMessageState = {
  messages: api.ChatMessage[]
  nextPageLink: string | null
}

const chatStore = defineStore('chat', () => {
  const authStore = useAuthStore()

  const state = {
    records: {
      chat: ref<api.Chat[]>([]),
      chat_member: ref<api.ChatMember[]>([]),
      chat_bookmark: ref<api.ChatBookmark[]>([]),
      role: ref<Role[]>([]),
      user: ref<User[]>([]),
      latest_message: ref<api.LatestMessage[]>([]),
    },
    currentChat: ref<api.Chat | null>(null),
    messages: ref<Map<string, ChatMessageState>>(new Map()),

    meta: {
      chat: ref<JsonApiMeta | null>(null),
    },
    searchKeyword: ref(''),
  }

  const currentChatMessages = computed(() => {
    const currentChat = state.currentChat.value
    if (!currentChat) return []

    const messagesState = state.messages.value.get(currentChat.id)
    if (!messagesState) return []

    const bookmarkTime = currentChat.bookmark
      ? new Date(currentChat.bookmark.updated_at)
      : null

    let isUnread = true
    const chatMessages = messagesState.messages
      .map((message) => {
        // Check if the message is unread
        if (isUnread && bookmarkTime) {
          isUnread =
            differenceInSeconds(new Date(message.created_at), bookmarkTime) >
              0 && message.author_id !== authStore.selectedUserId
        }
        return {
          ...message,
          isUnread,
        }
      })
      .reverse()

    // Mark only the first unread message
    let firstUnreadIsMarked = false
    chatMessages.forEach((message) => {
      if (!firstUnreadIsMarked) {
        if (message.isUnread) {
          firstUnreadIsMarked = true
        }
      } else {
        message.isUnread = false
      }
    })

    return chatMessages
  })

  function $resetState() {
    clearState(state)
  }

  async function fetchChats(
    page = state.meta.chat.value?.current_page,
    search = state.searchKeyword.value
  ) {
    state.searchKeyword.value = search

    const filters: FilterItem[] = search
      ? [{ key: 'subject', operator: FilterOperator.CT, value: search }]
      : []

    const { data, included = [], meta } = await api.listChats(page, filters)

    setRecords(state, { type: 'chat', records: data })
    addIncluded(state, { records: included })

    state.meta.chat.value = meta
  }

  async function fetchChatByContext(
    contextId: string,
    contextType: api.ChatContextType
  ) {
    const { data, included = [] } = await api.listChatsByContext(
      contextId,
      contextType
    )

    if (data.length) {
      state.currentChat.value = flattenRecord<api.Chat>(data[0])
      state.currentChat.value.bookmark = included?.[0]
        .attributes as api.ChatBookmark
    }
  }

  function closeCurrentChat() {
    state.currentChat.value = null
  }

  async function fetchMessagesForCurrentChat(replace: boolean = false) {
    const currentChatId = state.currentChat.value?.id
    if (!currentChatId) return

    const existingState = state.messages.value.get(currentChatId)

    let request: Promise<JsonApiListResponse<api.ChatMessage>>
    if (existingState === undefined || replace) {
      request = api.listMessagesByChat(currentChatId)
    } else if (existingState.nextPageLink) {
      request = paginationApi.listNextItems(existingState.nextPageLink)
    } else {
      return
    }

    const { data, included = [], links } = await request

    addIncluded(state, { records: included })

    const newMessages = data.map((chatMessage) =>
      restructureRecord(chatMessage, state)
    )

    // the "old" array is intentionally being reused here
    const messages = replace ? [] : existingState?.messages ?? []
    messages.push(...newMessages)

    state.messages.value.set(currentChatId, {
      messages,
      nextPageLink: links.next,
    })
  }

  function currentChatCanLoadMoreMessages() {
    const currentChatId = state.currentChat.value?.id

    return currentChatId
      ? state.messages.value.get(currentChatId)?.nextPageLink !== null
      : false
  }

  function createChatMessage(chatId: string, content: string) {
    return api.createChatMessage(chatId, content)
  }

  async function fetchChatMembersWithRoles(chatId: string) {
    const { data, included = [] } = await api.listChatMembers(chatId)

    setRecords(state, { type: 'chat_member', records: data })
    addIncluded(state, { records: included })
  }

  async function updateChatBookmark(chatId: string) {
    return api.updateChatBookmark(chatId)
  }

  function updateChat(chatId: string, payload: Partial<api.Chat>) {
    return api.updateChat(chatId, payload)
  }

  function prependMessage(chatId: string, message: api.ChatMessage) {
    const chatMessages = state.messages.value.get(chatId)

    chatMessages?.messages.unshift(message)
  }

  return {
    $resetState,

    chats: state.records.chat,
    currentChat: state.currentChat,
    messages: state.messages,
    chatMembers: state.records.chat_member,
    chatsMeta: state.meta.chat,
    searchKeyword: state.searchKeyword,

    fetchChats,
    createChatMessage,
    fetchChatByContext,
    fetchMessagesForCurrentChat,
    fetchChatMembersWithRoles,
    updateChatBookmark,
    updateChat,
    closeCurrentChat,

    currentChatMessages,
    currentChatCanLoadMoreMessages,
    prependMessage,
  }
})

export default chatStore
