import type { State, RecordGeneric, JsonApiDataItem } from './types'
import { getRef, restructureRecord } from './utils'
import { getRecordIndex, reindexSingle, reindexMultiple } from './indexing'

// replaces record by matching all fields given as `match` param. `replacement`
// can be `null`, which results in deleting matched record. If `match` object
// contains `id` param, this function searches for the record using index,
// which is faster
export function replaceRecord(
  state: State,
  {
    type,
    match,
    replacement,
  }: {
    type: string
    match: RecordGeneric
    replacement: RecordGeneric | null
  }
) {
  getRef(state, type)

  const index = getMatchIndex(state, type, match)

  if (index !== -1) {
    if (!replacement) {
      removeMatch(state, type, index)
      return -1
    } else {
      replaceMatch(state, type, index, replacement)
    }
  }

  return index
}

function getMatchIndex(
  state: State,
  type: string,
  match: RecordGeneric
): number {
  // when `id` field is in match, we go shorter route
  if (typeof match.id !== 'undefined') {
    return getRecordIndex(state, type, match.id)
  } else {
    const stateRecords = getRef(state, type)
    const matchKeys = Object.keys(match)

    return stateRecords.value.findIndex((currentItem: Record<string, any>) => {
      return matchKeys.every((key) => {
        return currentItem[key] === match[key]
      })
    })
  }
}

function removeMatch(state: State, type: string, index: number) {
  const stateRecords = getRef(state, type)
  stateRecords.value.splice(index, 1)
  reindexMultiple(state, type, index)
}

function replaceMatch(
  state: State,
  type: string,
  index: number,
  replacement: RecordGeneric
) {
  const stateRecords = getRef(state, type)
  const oldRecord = stateRecords.value[index]
  const newRecord = restructureRecord(
    replacement as JsonApiDataItem<RecordGeneric>,
    state
  )

  stateRecords.value.splice(index, 1, newRecord)
  reindexSingle(state, type, oldRecord.id, null)
  reindexSingle(state, type, newRecord.id, index)
}
