import type { SetOptional } from 'type-fest'
import api from './api'
import type { EditDeletePermission } from './types'
import type { User, UserMeta } from './user'
import type {
  JsonApiListResponse,
  JsonApiResponse,
} from '@papershift/jsonapi/src/types'
import { type FilterItem, transformQueryFilters } from './filter-utils'

export type Rights = {
  read?: boolean
  create?: boolean
  update?: boolean
  destroy?: boolean
}

export type Role = {
  id: string
  name: string
  role_type: 'custom' | 'system'
  rights: Record<string, Rights>
  scope: 'account' | 'chat' | 'team'
  template_id?: string
  permissions?: {
    actions?: EditDeletePermission & { rights: { edit: boolean } }
  }
}

export type AccountMembership = {
  id: string
  account_id: string
  user_id: string
  role_id: string
}

export type TeamMembership = {
  id: string
  team_id: string
  user_id: string
  role_id: string
}

export type RolePayload = Omit<
  SetOptional<Role, 'rights' | 'template_id'>,
  'id' | 'role_type'
>

export function listRoles(
  filters: FilterItem[] = [],
  page = 1
): Promise<JsonApiListResponse<Role>> {
  const query = transformQueryFilters(filters)

  return api
    .url('/api/v1/roles')
    .query({
      'meta[permissions]': 'actions',
      'page[number]': page,
      'page[size]': 20,
      sort: 'name',
      ...query,
    })
    .get()
    .json()
}

export function createRole(
  payload: RolePayload
): Promise<JsonApiResponse<Role>> {
  return api
    .url(`/api/v1/roles`)
    .post({
      data: {
        type: 'role',
        attributes: {
          name: payload.name,
          template_id: payload.template_id,
          scope: payload.scope,
        },
      },
    })
    .json()
}

export function getRole(roleId: string): Promise<JsonApiResponse<Role>> {
  return api
    .url(`/api/v1/roles/${roleId}?meta[permissions]=actions`)
    .get()
    .json()
}

export async function updateRole(
  roleId: string,
  payload: Partial<RolePayload>
): Promise<JsonApiResponse<Role>> {
  return api
    .url(`/api/v1/roles/${roleId}`)
    .put({
      data: {
        type: 'role',
        attributes: {
          name: payload.name,
          rights: payload.rights,
        },
      },
    })
    .json()
}

export function deleteRole(roleId: string): Promise<JsonApiResponse<Role>> {
  return api.url(`/api/v1/roles/${roleId}`).delete().json()
}

export function editTeamMembership(
  teamId: string,
  userId: string,
  roleIds: string[]
): Promise<JsonApiResponse<User, UserMeta>> {
  return api
    .url(
      `/api/v1/team_memberships?include=team_memberships&filter[team_memberships][team_id]=eq:${teamId}`
    )
    .post({
      data: {
        type: 'team_membership',
        attributes: {
          team_id: teamId,
          user_id: userId,
          role_ids: roleIds,
        },
      },
    })
    .json()
}

export function createAccountMembership(
  userId: string,
  roleIds: string[]
): Promise<JsonApiResponse<User>> {
  return api
    .url('/api/v1/account_memberships')
    .post({
      data: {
        type: 'account_membership',
        attributes: {
          user_id: userId,
          role_ids: roleIds,
        },
      },
    })
    .json()
}

export function editChatMembership(
  chatId: string,
  userId: string,
  roleIds: string[]
): Promise<JsonApiResponse<User>> {
  return api
    .url(`/api/v1/chat_memberships`)
    .post({
      data: {
        type: 'chat_membership',
        attributes: {
          chat_id: chatId,
          user_id: userId,
          role_ids: roleIds,
        },
      },
    })
    .json()
}
