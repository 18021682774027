import { isReadonly, isRef, type Ref } from 'vue'
import isPlainObject from 'lodash/isPlainObject'
import type { RecordsCollection, State } from '@papershift/jsonapi/src/types'
import { clearRecords } from '@papershift/jsonapi/src/records.mutations'

type ClearablePlainValue =
  | string
  | number
  | boolean
  | Set<unknown>
  | Map<unknown, unknown>
  | Array<unknown>

// plain objects can only be cleared if they are wrapped in a ref;
// otherwise, `clearState` recurses into the object's keys
type ClearableRef = Ref<ClearablePlainValue | (object | null)>
type ClearableValue = ClearablePlainValue | ClearableRef | NestedClearableValues
type ClearableState = NestedClearableValues & { records?: RecordsCollection }
type NestedClearableValues = { [K in string]: ClearableValue }

// clears given state object by going through each object Vue Ref item.
// "clearing" means setting items to "empty values" (0, false, '', [], null, etc.)
// when jsonapi `records` are present, it uses `clearRecords()` to clear them instead.
// it also recursively goes through the nested objects.
// non-Ref items are returned as-is.
export function clearState(state: ClearableState) {
  if (state.records) clearAllRecords(state as State)

  for (const [key, value] of Object.entries(state)) {
    if (key === 'records') continue

    clearRefsDeep(value)
  }
}

function clearAllRecords(state: State) {
  for (const [recordType, record] of Object.entries(state.records)) {
    if (isReadonly(record)) continue

    clearRecords(state, { type: recordType })
  }
}

function clearRefsDeep(value: ClearableValue) {
  if (typeof value !== 'object') return

  if (isRef(value)) {
    clearRef(value)
    return
  }

  for (const nestedValue of Object.values(value)) {
    clearRefsDeep(nestedValue)
  }
}

function clearRef(ref: ClearableRef) {
  const value = emptyValue(ref.value)

  if (value !== undefined) {
    ref.value = value
  }
}

function emptyValue(value: ClearablePlainValue | (object | null)) {
  if (Array.isArray(value)) {
    return []
  } else if (value instanceof Map) {
    return new Map()
  } else if (value instanceof Set) {
    return new Set()
  } else if (isPlainObject(value)) {
    return null
  } else {
    switch (typeof value) {
      case 'string':
        return ''
      case 'number':
        return 0
      case 'boolean':
        return false
      default:
        return undefined
    }
  }
}
