<script setup lang="ts" generic="T">
import { type Component, ref } from 'vue'
import { useFloating, autoUpdate, flip } from '@floating-ui/vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import DropdownOption from './DropdownOption.vue'

export type Option<T = unknown> = {
  value: T
  label: string
  icon?: Component
  theme?: 'default' | 'danger'
}

withDefaults(
  defineProps<{
    id: string
    options: Option<T>[]
    tabindex?: number
    wide?: boolean
  }>(),
  {
    wide: false,
  }
)

defineEmits<{
  'option-select': [value: Option<T>]
}>()

const floatingReference = ref(null)
const floatingElem = ref(null)

// TODO boykov: [PULSE-2563]
// const middlewareOptions = props.shiftBox ? [shift({ padding: 50 })] : [flip()]

const { floatingStyles } = useFloating(floatingReference, floatingElem, {
  transform: false,
  placement: 'bottom-end',
  whileElementsMounted: autoUpdate,
  middleware: [flip()],
})
</script>

<template>
  <Menu v-slot="{ open }" as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        ref="floatingReference"
        class="inline-flex w-full gap-x-1.5 rounded-md bg-neutral-50 px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <span class="sr-only">Open options</span>
        <slot name="trigger" />
      </MenuButton>
    </div>

    <teleport to="body">
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          v-show="open"
          ref="floatingElem"
          :static="true"
          :style="floatingStyles"
          :class="[
            wide ? 'w-60' : 'w-48',
            'max-h-80 sm:max-h-auto overflow-y-auto absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-neutral-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          ]"
        >
          <MenuItem
            v-for="(option, index) in options"
            :key="index"
            v-slot="{ active, close }"
          >
            <slot
              name="option"
              :option="option"
              :active="active"
              :close="close"
            >
              <DropdownOption
                :active="active"
                :icon="option.icon"
                :label="option.label"
                :theme="option.theme"
                @click="$emit('option-select', option)"
              />
            </slot>
          </MenuItem>
        </MenuItems>
      </transition>
    </teleport>
  </Menu>
</template>
