<script lang="ts">
import type {
  ValidationStatic,
  ValidationLazy,
} from './composables/use-validation-schema'

export type { ValidationStatic, ValidationLazy }

export type Props = {
  actionStatus: Status
  submitHandler: () => Promise<void>
  validation?: ValidationStatic | ValidationLazy
}
</script>

<script setup lang="ts">
import { onUnmounted, ref, watch, type VNode } from 'vue'
import {
  type FormActions,
  type FormContext,
  configure,
  Form,
} from 'vee-validate'
import type { Status } from '@papershift/action-status/src/types'
import Message from './Message.vue'
import useValidationSchema from './composables/use-validation-schema'
import useValidationMessages from './composables/use-validation-messages'
import i18n, { currentLocale, addMessages } from '@papershift/locale/src/i18n'

const { t } = i18n.global

addMessages({
  en: {
    form_error: {
      title: 'There was an error while submitting the form',
    },
  },
  de: {
    form_error: {
      title: 'Beim Senden des Formulars ist ein Fehler aufgetreten',
    },
  },
})

configure({
  validateOnBlur: false,
})

const props = withDefaults(defineProps<Props>(), {
  submitHandler: () => Promise.resolve(),
})
const slots = defineSlots<{
  default(): VNode[]
}>()

const form = ref<FormContext | null>(null)
let submitTimeoutId: ReturnType<typeof setTimeout>

const { validationSchema } = useValidationSchema(
  slots.default(),
  props.validation
)

const generalError = ref('')
const { getUnregisteredFieldsMessages } = useValidationMessages()

const hasSubmitted = ref(false)

const submit = async (
  fields: Record<string, any>,
  { setErrors }: FormActions<Record<string, unknown>>
) => {
  if (hasSubmitted.value) return

  hasSubmitted.value = true
  generalError.value = ''

  submitTimeoutId = setTimeout(() => {
    hasSubmitted.value = false
  }, 600)

  try {
    return await props.submitHandler()
  } catch (e) {
    if (props.actionStatus.hasErrorMessages) {
      const validationMessages = props.actionStatus.validationMessages

      if (validationMessages.size) {
        setErrors(Object.fromEntries(validationMessages))

        generalError.value = getUnregisteredFieldsMessages(
          fields,
          validationMessages
        )
      } else {
        generalError.value = props.actionStatus.errorMessages.join()
      }
    } else if (e instanceof Error) {
      generalError.value = e.message
    }
  }
}

// re-run validation when locale changes
watch(
  () => currentLocale.value,
  () => form.value?.validate()
)

onUnmounted(() => {
  clearTimeout(submitTimeoutId)
})
</script>

<template>
  <Form
    ref="form"
    v-bind="$attrs"
    :validation-schema="validationSchema"
    @submit="submit"
  >
    <Message
      v-if="generalError"
      id="error-message"
      type="error"
      :title="t('form_error.title')"
    >
      {{ generalError }}
    </Message>

    <slot />
  </Form>
</template>
