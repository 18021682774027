import throttle from 'lodash/throttle'
import useChatStore from '@/stores/chat/chat.store'
import type { DebouncedFunc } from 'lodash'

const BOOKMARK_UPDATE_DELAY = 3000 // limit sending bookmark request to only once every 3 seconds

export default function useChatBookmark() {
  const chatStore = useChatStore()
  let updateCall: DebouncedFunc<typeof chatStore.updateChatBookmark>

  function deferBookmarkUpdate() {
    if (!updateCall) {
      updateCall = throttle(
        chatStore.updateChatBookmark,
        BOOKMARK_UPDATE_DELAY,
        { leading: false }
      )
    }
    return updateCall(chatStore.currentChat!.id)
  }

  function triggerBookmarkUpdate() {
    if (updateCall) {
      updateCall.flush()
    }
  }

  function abortBookmarkUpdate() {
    if (updateCall) {
      updateCall.cancel()
    }
  }

  return {
    deferBookmarkUpdate,
    triggerBookmarkUpdate,
    abortBookmarkUpdate,
  }
}
